body,
body > div {
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

* {
    font-family: "Manrope";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Manrope";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
